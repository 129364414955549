import React from "react"
import EventCard from "./EventCard"
import styled from "styled-components"

const Container = styled.div`
  width: 50%;
  padding: 1.5%;
  @media (max-width: 800px) {
    width: 100%;
  }
`
const Title = styled.h2`
  padding: 1% 0% 4% 1%;
  margin: 0px;
  font-family: "Archivo", sans-serif;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  text-transform: uppercase;
  letter-spacing: 0.055em;
  @media (max-width: 1100px) {
    font-size: 28px;
  }
  @media (max-width: 700px) {
    font-size: 24px;
  }
  @media (max-width: 500px) {
    font-size: 22px;
  }
`

function EventsList(props) {
  const dataEvents = props.data;
  return (
    <Container>
      <Title>eventos de {props.nameMonth}</Title>
      {dataEvents.map(elem => {
        return (
          <EventCard
            date={elem.date}
            name={elem.name}
            place={elem.place}
            status={elem.status}
            image={elem.image}
          />
        )
      })}
    </Container>
  )
}

export default EventsList
