import React from "react"

//importación de componentes
import MainLayout from "../components/Layouts/MainLayout"
import Picture from "../components/Picture"
import EventsContainer from "../components/EventsContainer"
import NewsContainer from "../components/NewsContainer"

const dataNews = [
  {
    id: 1,
    date: "1 de febrero del 2020",
    name:
      "Massachusetts institute of Technology nos reconoce como especialistas en formación de Data Science",
    place: "",
    description:
      "Munther Dahleh, Director del Institute for Data, Systems and Society (IDSS), resalta la labor de nuestro instituto: “Aporta y el IDSS comparten la misión de dominar la formación en skills analíticos... juntos estamos aprendiendo cómo es la mejor forma de preparar y soportar a los estudiantes en adquirir estas habilidades. ",
    link: "https://idss.mit.edu/news/partnering-to-advance-data-science-",
    image: "/newImg.jpg",
  },
  {
    id: 2,
    date: "1 de febrero del 2020",
    name: "Data science para el impacto social",
    place: "",
    description:
      "A través de nuestra iniciativa de Data Science for Social Good, nuestros alumnos de la primera generación desarrollaron cuatro soluciones para el desarrollo social aplicando análisis de datos.  Queremos agradecer a los mentores, expertos locales y expertos del MIT que nos acompañaron durante el proceso.  Asimismo, a los equipos @CARE, @Liga Contra el Cáncer, @GRADE y @Viva por trabajar junto con estos talentos, este es un paso más hacia la modernización y fortalecimiento de iniciativas sociales en nuestro país. ",
    image: "/newImg.jpg",
  },
]

export default function Events() {
  return (
    <MainLayout active={5}>
      <Picture image="/eventsImage.png">
        No te pierdas ninguno de nuestros eventos
      </Picture>
      <NewsContainer title="NOTICIAS" isNews={true} data={dataNews} />
      <EventsContainer />
    </MainLayout>
  )
}
