import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"

const Container = styled.div`
  margin-top: 2%;
  width: 50%;
  @media (max-width: 800px) {
    width: 100%;
  }
`

const Frame = styled.div`
  width: 100%;
  height: 567px;
  background-color: #f6f9fb;
  border-radius: 32px;
  padding: 1px 30px 5px 30px;
  font-family: "Archivo";
`

const Header = styled.div`
  height: 100px;
  font-size: 24px;
  padding: 10px 10px 5px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 32px;
  letter-spacing: 0.12px;
`

const Button = styled.div`
  cursor: pointer;
  .icon {
    height: 100%;
    font-size: 30px;
    width: 20px;
    margin-bottom: 0;
  }
`

const Body = styled.div`
  height: 450px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background-color: #f6f9fb;
  .nameOfDay {
    font-size: 14px;
  }
  .day {
    color: #7a7585;
  }
`

const Day = styled.div`
  width: 14.2%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  pointer-events: none;
  ${props =>
    props.isToday &&
    css`
      // border: 1px solid #eee;
      //background-color: #ffffff;
      border-radius: 8px;
    `}

  ${props => props.isSelected && css``}
`

const CStyled = styled.div`
  ${props =>
    props.isSelected &&
    css`
      display: flex;
      width: 40px;
      height: 40px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      pointer-events: auto;
      background-color: #03878f;
      border-radius: 50%;
      font-weight: 600;

      color: #ffffff;
    `}
`

function Calendar(props) {
  const DAYS = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
  const DAYS_LEAP = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
  const DAYS_OF_THE_WEEK = ["DOM", "LUN", "MAR", "MIE", "JUE", "VIE", "SAB"]
  const MONTHS = props.months
  const events = props.data;
  
  const today = new Date()
  const [date, setDate] = useState(today)
  const [day, setDay] = useState(date.getDate())
  const [month, setMonth] = useState(date.getMonth())
  const [year, setYear] = useState(date.getFullYear())
  const [startDay, setStartDay] = useState(getStartDayOfMonth(date))

  useEffect(() => {
    setDay(date.getDate())
    setMonth(date.getMonth())
    setYear(date.getFullYear())
    setStartDay(getStartDayOfMonth(date))
  }, [date])

  function getStartDayOfMonth(date) {
    return new Date(date.getFullYear(), date.getMonth(), 1).getDay()
  }

  function isLeapYear(year) {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0
  }

  const days = isLeapYear ? DAYS_LEAP : DAYS


  return (
    <Container>
      <Frame>
        <Header>
          <Button onClick={() => {
            setDate(new Date(year, month - 1, 1))
            props.updateMonth(month - 1)
            props.updateDay(undefined)
            }}>
            <img src="/images/icon.png" />
          </Button>
          <div>
            {MONTHS[month]} {year}
          </div>
          <Button onClick={() => {
            setDate(new Date(year, month + 1, 1))
            props.updateMonth(month + 1)
            props.updateDay(undefined)
            }}>
            <img src="/images/icon2.png" />
          </Button>
        </Header>
        <Body>
          {DAYS_OF_THE_WEEK.map(d => (
            <Day className="nameOfDay" key={d}>
              <strong>{d}</strong>
            </Day>
          ))}
          {Array(days[month] + startDay)
            .fill(null)
            .map((_, index) => {
              const d = index - (startDay - 1)
              return (
                <Day
                  className="day"
                  key={index}
                  isToday={d === today.getDate()}
                  isSelected={d === day}
                  onClick={() => {
                    setDate(new Date(year, month, d))
                    props.updateDay(d)
                  }}
                >
                  {d > 0 ? (
                    (events.map(elem => elem.fulldate)).indexOf(`${d}/${month + 1}/${year}`) === -1 ? (
                      <CStyled isSelected={false}>
                        <div>{d} </div>
                      </CStyled>
                    ) : (
                      <CStyled isSelected={true}>
                        <div>{d} </div>
                      </CStyled>
                    )
                  ) : (
                    ""
                  )}
                </Day>
              )
            })}
        </Body>
      </Frame>
    </Container>
  )
}

export default Calendar
