import React from "react"
import styled from "styled-components"

const DivCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid #d5f5fa;
  padding-top: 3%;
  padding-bottom: 3%;
`
const DivImg = styled.div`
  width: 20%;
`
const Img = styled.img`
  width: 80%;
`
const DivInfo = styled.div`
  width: 80%;
  padding-right: 7%;
  font-family: Archivo;
  font-style: normal;
`
const TextDate = styled.p`
  text-transform: uppercase;
  color: #22959c;
  font-size: 14px;
  @media (max-width: 1231px) {
    margin-bottom: 0.7%;
  }
  @media (max-width: 500px) {
    font-size: 11px;
  }
`
const DivData = styled.div`
  display: flex;
  flex-direction: row;
`
const TextName = styled.p`
  text-transform: uppercase;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 2%;
  @media (max-width: 500px) {
    font-size: 14px;
  }
`
const DivStatus = styled.div`
  text-transform: uppercase;
  background: ${props => (props.active === "agotado") ? '#ec5959' : '#03878F'};
  border-radius: 15px;
  font-size: 12px;
  color: white;
  margin-left: 4%;
  width: 90px;
  height: 20px;
  text-align: center;
  @media (max-width: 500px) {
    font-size: 11px;
  }
`
const TextPlace = styled.p`
  color: #767b8a;
  font-size: 15px;
  @media (max-width: 500px) {
    font-size: 13px;
    margin-bottom: 0.7%;
  }
`
function EventCard(props) {
  return (
    <DivCard>
      <DivImg>
        <Img src={props.image} />
      </DivImg>
      <DivInfo>
        <TextDate>{props.date}</TextDate>
        <DivData>
          <TextName>{props.name}</TextName>
          <DivStatus active={props.status}>{props.status}</DivStatus>
        </DivData>
        <TextPlace>{props.place}</TextPlace>
      </DivInfo>
    </DivCard>
  )
}

export default EventCard
