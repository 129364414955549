import React, {useState, useEffect} from "react"
import EventList from "./EventsList"
import Calendar from "./Calendar"
import styled from "styled-components"

const ContainerDiv = styled.div`
  margin: 4% 4% 8% 4%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`
const dataEvents = [
  {
    id: 1,
    date: "28 de agosto del 2020",
    fulldate: "28/8/2020",
    month: 8,
    name: "evento del mes de agosto",
    place: "Surco - Lima",
    status: "agotado",
    image: "/images/events/event1.jpg",
  },
  {
    id: 2,
    date: "28 de agosto del 2020",
    fulldate: "28/8/2020",
    month: 8,
    name: "evento del mes de agosto",
    place: "Surco - Lima",
    status: "agotado",
    image: "/images/events/event2.jpg",
  },
  {
    id: 3,
    date: "30 de agosto del 2020",
    fulldate: "30/8/2020",
    month: 8,
    name: "evento del mes de agosto",
    place: "Surco - Lima",
    status: "agotado",
    image: "/images/events/event1.jpg",
  },
  {
    id: 4,
    date: "31 de agosto del 2020",
    fulldate: "31/8/2020",
    month: 8,
    name: "evento del mes de agosto",
    place: "Surco - Lima",
    status: "inscripción",
    image: "/images/events/event2.jpg",
  },
  {
    id: 5,
    date: "30 de septiembre del 2020",
    fulldate: "30/9/2020",
    month: 9,
    name: "evento del mes de septiembre",
    place: "Surco - Lima",
    status: "inscripción",
    image: "/images/events/event2.jpg",
  },
  {
    id: 6,
    date: "30 de enero del 2021",
    fulldate: "30/1/2021",
    month: 1,
    name: "evento del mes de septiembre",
    place: "Surco - Lima",
    status: "inscripción",
    image: "/images/events/event2.jpg",
  },
]

function EventsContainer(props) {
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth())
  const [day, setDay] = useState(undefined)
  const [data, setData] = useState([]);

  useEffect(() => {
    let month;
    (currentMonth === 12) ? month = 0 : (currentMonth === -1) ? month = 11 :month = currentMonth;
    setCurrentMonth(month);
    let newData;
    day === undefined ?
      newData = dataEvents.filter(elem => elem.month === currentMonth +1)
      : newData = dataEvents.filter(elem => elem.fulldate === `${day}/${currentMonth + 1}/${new Date().getFullYear()}`);
    setData(newData);
  }, [currentMonth, day])
  
 

  const MONTHS = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ]
  return (
    <ContainerDiv>
      <EventList data = {data} nameMonth={MONTHS[currentMonth]} />
      <Calendar months={MONTHS} data = {dataEvents} updateMonth={setCurrentMonth} updateDay={setDay} />
    </ContainerDiv>
  )
}

export default EventsContainer
